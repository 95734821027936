import React, { useRef } from 'react';
import { Canvas } from '@react-three/fiber';
import FontThreeJs from './FontThreeJs';
import { OrbitControls } from '@react-three/drei'

import { useThree, useFrame } from '@react-three/fiber';


import CameraControls from 'camera-controls'

import { extend } from '@react-three/fiber'

import * as THREE from 'three';


const CanvasThreeFiberGyro = () => {

    ///// all for mouse move
    CameraControls.install({ THREE })
    extend({ CameraControls })

    function Controls() {
        const ref = useRef()
        const camera = useThree((state) => state.camera)
        const gl = useThree((state) => state.gl)

        // Initialize CameraControls with damping enabled
        /*     if (ref.current && !ref.current.damping) {
                ref.current.enableDamping = true; // Enable damping
                ref.current.dampingFactor = 0.05; // Set damping factor
            } */
        /*    if (ref.current) {
               ref.current.enableSmoothDamp = true; // Enable SmoothDamp
               ref.current.smoothTime = 0.5; // Set smoothTime for transition speed
           } */



        /*      useFrame((state, delta) => {
                
     
                 ref.current.azimuthAngle = -state.mouse.x * 0.15; // Slow down the movement
                 ref.current.polarAngle = Math.PI / 2 + state.mouse.y * 0.15; // Slow down the movement
     
                 ref.current.update(delta)
             }) */

        return <cameraControls ref={ref} args={[camera, gl.domElement]} />
    }

    //// all for mouse move

    return (
        <Canvas camera={{ position: [0.5, 0.5, 0.25] }} style={{ width: '100vw', height: '100vh', display: 'block', position: 'fixed', top: 0, left: 0, cursor: 'grab' }}>
            <ambientLight intensity={3} />

            <directionalLight position={[0, 1, 1]} />  {/* front */}
            <directionalLight position={[0, 1, -1]} /> {/* back */}

            <FontThreeJs />

            <OrbitControls />
            <Controls />
        </Canvas>
    );
};

export default CanvasThreeFiberGyro;